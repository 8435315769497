
import { defineAsyncComponent, defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import QuestionModal from "@/components/modals/forms/QuestionModal.vue";
import { Modal } from "bootstrap";
import DeleteQuestion from "@/components/swal/DeleteQuestion.vue";
import { useRouter } from "vue-router";
import DropdownExport from "@/components/dropdown/DropdownExport.vue";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";

export default defineComponent({
  name: "question_table",

  components: { GlobalTable, QuestionModal, DeleteQuestion, DropdownExport },
  setup: function () {
    let item = ref();
    let change = ref<boolean>(false);
    let updaterID = ref(null);
    let deleterData = ref({});
    let checkDeleter = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const currentSort = ref<string>("");
    let search = ref<string | null>(null);
    let dataCan = ref(false);
    let getDataExist = ref<boolean>(true);
    const column = ref<string>("id");
    const orderType = ref<string>("desc");

    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 10,
    });
    const router = useRouter();
    let page = ref<number>(1);
    let perPage = ref<number>(10);
    let data = ref([]);

    const tableHeader = [
      {
        name: "ID",
        key: "id",
        sortable: false,
      },
      {
        name: "description",
        key: "description",
        sortable: false,
      },
      {
        name: "section",
        key: "section",
        sortable: false,
      },
      {
        name: "order",
        key: "order",
        sortable: false,
      },
      {
        name: "type",
        key: "type",
        sortable: false,
      },
      {
        name: "list",
        key: "list",
        sortable: false,
      },
      {
        name: "min value",
        key: "min",
        sortable: false,
      },
      {
        name: "max value",
        key: "max",
        sortable: false,
      },
      {
        name: "Comment",
        key: "comment",
        sortable: false,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ];
    onMounted(() => {
      getQuestions(perPage.value, page.value, column.value, orderType.value);
    });

    function getQuestions(perPage, page, column, orderType) {
      search.value = null;
      dataCan.value = false;
      loading.value = true;
      apiService
        .get(
          "/question",
          `?perPage=${perPage}&page=${page}&column=${column}&orderType=${orderType}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            //pagination.value.page = resp.data?.meta.current_page;
            //pagination.value.total = resp.data?.meta.total;
            //pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
            dataCan.value = true;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function searchQuestions(perPage, page, query, column, orderType) {
      loading.value = true;
      apiService
        .get(
          "/question/search",
          `?query=${query}&perPage=${perPage}&page=${page}&column=${column}&orderType=${orderType}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data.data;
            //pagination.value.page = resp.data.meta.current_page;
            //pagination.value.total = resp.data.meta.total;
            //pagination.value.rowsPerPage = resp.data.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    const existData = async (resp) => {
      getDataExist.value = false;
      if (resp) {
        getDataExist.value = await resp;
      }
    };
    const orderedData = (resp) => {
      column.value = resp.column;
      orderType.value = resp.sorting;
      getQuestions(perPage.value, page.value, resp.column, resp.sorting);
    };
    const setItemsPerPage = (event) => {
      page.value = 1;
      perPage.value = event.target.value;
      if (search.value != null && search.value != "") {
        searchQuestions(
          perPage.value,
          page.value,
          search.value,
          column.value,
          orderType.value
        );
      } else {
        getQuestions(perPage.value, page.value, column.value, orderType.value);
      }
    };

    const currentPageChange = (val) => {
      page.value = val;
      //pagination.value.page = val;
      if (search.value != null && search.value != "") {
        searchQuestions(
          perPage.value,
          val,
          search.value,
          column.value,
          orderType.value
        );
      } else {
        getQuestions(perPage.value, val, column.value, orderType.value);
      }
    };

    const setSearchValue = (event) => {
      search.value = event.target.value;
      if (!search.value?.trim()) {
        getQuestions(perPage.value, page.value, column.value, orderType.value);
        return;
      }
      searchQuestions(
        perPage.value,
        page.value,
        search.value,
        column.value,
        orderType.value
      );
    };
    const newOne = (resp) => {
      if (resp === "new") {
        newQuestion();
      }
    };
    const checkModal = (resp) => {
      if (resp.status == "updated") {
        let array = JSON.parse(JSON.stringify(data.value));
        let foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
      } else if (resp.status == "created") {
        page.value = 1;
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      } else if (resp.status == "deleted") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };

    const editQuestion = (data) => {
      data.status = Boolean(data.status);
      item.value = data;
      change.value = !change.value;
      updaterID.value = data.id;
      let modelDIv = document.getElementById("kt_modal_question");
      modelDIv ? new Modal(modelDIv).show() : null;
    };

    const newQuestion = () => {
      item.value = null;
      change.value = !change.value;
      updaterID.value = null;
      // eslint-disable-next-line
      let modelDIv = document.getElementById("kt_modal_question")
      modelDIv ? new Modal(modelDIv).show() : null;
    };

    const DeleteQuestion = (id, code) => {
      checkDeleter.value = !checkDeleter.value;
      deleterData.value = {
        id: id,
        code: code,
      };
    };

    return {
      loading,
      tableHeader,
      data,
      setItemsPerPage,
      currentPageChange,
      pagination,
      perPage,
      page,
      search,
      setSearchValue,
      currentSort,
      checkModal,
      editQuestion,
      item,
      newQuestion,
      updaterID,
      checkDeleter,
      deleterData,
      change,
      DeleteQuestion,
      dataCan,
      getDataExist,
      existData,
      orderedData,
      column,
      orderType,
      newOne,
    };
  },
});
