
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "question-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    data: {
      type: Object,
    },
    change: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
    },
    perPage: {
      required: true,
    },
    column: {
      required: true,
    },
    orderType: {
      required: true,
    },
  },
  emit: ["modal-value"],

  setup: function (props, { emit }) {
    let submitButtonRef = ref<null | HTMLButtonElement>(null);
    const questionModalRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    let formRef = ref<null | HTMLElement>();
    let sectionRef = ref([]);
    let typeRef = ref([]);
    let listRef = ref([]);
    let listStatus = ref<number>(1);
    let errorsRef = ref(null);
    let canSend = ref<boolean>(true);

    let questionData = ref({
      order: null,
      min: 1,
      max: 5,
      description: "",
      type_id: "",
      list_id: "",
      section_id: "",
      comment: null,
      perPage: props.perPage,
      page: 1,
      column: props.column,
      orderType: props.orderType,
    });
    onMounted(async () => {
      await getSections();
      await getTypes();
      await getList();
    });
    watch(
      () => props.change,
      () => {
        canSend.value = true;
        if (props.id) {
          questionData.value.section_id = props.data?.section.id;
          questionData.value.list_id = props.data?.list?.id;
          questionData.value.type_id = props.data?.type?.id;
          listStatus.value = props.data?.type?.status;
          Object.assign(questionData.value, props.data);
        } else {
          listStatus.value = 0;
          questionData.value = {
            order: null,
            min: 1,
            max: 5,
            description: "",
            type_id: "",
            list_id: "",
            section_id: "",
            comment: null,
            perPage: props.perPage,
            page: 1,
            column: props.column,
            orderType: props.orderType,
          };

          let button = document.getElementById("kt_modal_question_cancel");
          button?.click();
        }
      }
    );

    function getSections() {
      apiService
        .get("/section")
        .then((resp) => {
          sectionRef.value = resp.data.data;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function getTypes() {
      apiService
        .get("/question/types")
        .then((resp) => {
          typeRef.value = resp.data.data;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function getList() {
      apiService
        .get("/list/group")
        .then((resp) => {
          listRef.value = resp.data.data;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function validator() {
      let validation;
      validation = {
        description: Yup.string()
          .min(2)
          .required()
          .label("Question Description"),
        order: Yup.number().required().label("Question Order"),
        min: Yup.number()
          .typeError("you must specify a number")
          .min(1, "Min value is 1")
          .max(
            questionData.value.max,
            "Can't be greater than max value or than 5"
          )
          .required()
          .label("Min"),
        max: Yup.number()
          .typeError("you must specify a number")
          .min(
            questionData.value.min,
            "Can't be less than min value or than 1 "
          )
          .max(5, "Max value is 5")
          .required()
          .label("Max"),
        type_id: Yup.string().required().label("Question Order"),
        section_id: Yup.string().required().label("Section List"),
      };
      if (listStatus.value === 1) {
        validation.list_id = Yup.string().required().label("Question List");
      }
      return Yup.object().shape(validation);
    }

    const validationSchema = computed(() => {
      return validator();
    });

    const changeType = (event) => {
      hideError("type_id");
      let json = JSON.parse(JSON.stringify(typeRef.value));
      let foundIndex = json.findIndex((x) => x.id == event.target.value);
      listStatus.value = json[foundIndex]?.status ?? 0;
      if (listStatus.value == 0 && !props.id) {
        questionData.value.list_id = "";
      }
    };

    const submit = async () => {
      if(!canSend.value){
        return false;
      }
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButtonRef.value || count > 0) {
        return;
      }
      let json = JSON.parse(JSON.stringify(questionData.value));
      json.list_id == null || json.list_id == "" ? delete json.list_id : null;
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      if (props.id) {
        await apiService
          .put("/question/" + props.id, json)
          .then((resp) => {

            let data = {
              status: "updated",
              data: resp.data.data,
            };
            canSend.value = false;

            success(data, "updated");
          })
          .catch(function (error) {
            canSend.value = true;

            hideLoading();
            if (error.response && error.response.status == 422) {
              getError(error.response?.data.errors);
            }
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
          });
      } else {
        await apiService
          .post("/question", json)
          .then((resp) => {
            canSend.value = true;
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            let data = {
              status: "created",
              data: resp.data,
            };
            canSend.value = false;

            success(data, "created");
          })
          .catch(function (error) {
            canSend.value = true;
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
            hideLoading();
            if (error.response && error.response.status == 422) {
              getError(error.response?.data.errors);
            }
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
          });
      }

      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    };
    function hideLoading() {
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    }
    function getError(error) {
      errorsRef.value = error;
    }

    function success(data,method) {

      emit("modal-value", data);
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      if(method === "created"){
        Swal.fire({
          text: `The section has been successfully ${data.status}!`,
          icon: "success",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Add Section",
          cancelButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then((result) => {
          hideModal(questionModalRef.value);
          if (result.isConfirmed) {
            canSend.value = true;
            emit("new-modal", "new");
          }
        });
      } else if (method === "updated") {
        Swal.fire({
          text: `The question has been successfully ${data.status}!`,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          canSend.value = true;
          hideModal(questionModalRef.value);
        });
      }
    }

    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };
    return {
      validationSchema,
      submit,
      submitButtonRef,
      formRef,
      questionData,
      questionModalRef,
      sectionRef,
      typeRef,
      listStatus,
      listRef,
      changeType,
      errorsRef,
      hideError,
      canSend,
    };
  },
});
