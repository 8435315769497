
import { defineComponent, onMounted } from "vue";
import QuestionTable from "@/components/widgets/tables/QuestionTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "questions",
  components: {
    QuestionTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Questions");
    });
  },
});
